import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";

export const frontmatter = {
  title: "Día 49",
  week: "Semana 7",
  month: "may",
  day: "01",
  monthNumber: "05",
  date: "2020-05-01",
  path: "/cronologia/semana-07#dia-01-may",
};
const Day49 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModGraph
          name="01-05-es-evolucionsemanal-dia"
          alt="evolucion semanal contagiados y fallecidos acumulados por coronavirus"
        />
        <ModCCAATable fecha={frontmatter.date} />
        <ModGraph
          name="01-05-es-activos-intradia"
          alt="incremento de activos coronavirus españa 1 de mayo"
        />
        <ModDataEu fecha={"2020-05-01"} />
        <ModLethalityEu fecha={"2020-05-01"} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 3.619 nuevos positivos y 281 personas fallecidas.
          Permanecen hospitalizadas 117.393 personas, 732 más que el día
          anterior, y 2.628 reciben el alta. El número de casos ingresados en la
          UCI asciende a 10.860, lo que supone un aumento de 84 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,2 % y la tasa de recuperados del 47,2 %.
        </ModText>
        <ModText>
          A partir de mañana, 2 de mayo, se podrá salir a hacer deporte y
          pasear. Los horarios y pautas que debemos adoptar son los siguientes:
        </ModText>
        <ModDotList
          item1="Menores de 14 años: máximo 3 y con un adulto, de 12-19h"
          item2="Personas de 14 años en adelante: 6-10h y de 20-23h"
          item3="Mayores de 70 o personas dependientes de
          un cuidador: 10-12h y de 19-20h"
          item4="Deporte no profesional: 6-10h y de
          20-23h"
        />
        <ModText>
          Los{" "}
          <strong>
            municipios que tengan una población igual o inferior a 5.000
            habitantes no tendran franjas horarias
          </strong>
          . El horario para estas actividades será de 6-23h.
        </ModText>
        <ModImage
          src="/images/svg/01_may_deporte.svg"
          alt="franja horaria para deporte en epoca de covid-19"
        />
        <ModText>
          En cuanto al deporte, deberá practicarse de manera individual, sin
          contacto, una vez al día y <strong>dentro del municipio</strong>. Los
          paseos, en cambio, se podrán realizar con una persona conviviente, una
          vez al día y no más de un kilómetro. Las personas que tengan que salir
          acompañadas podrán hacerlo también con un cuidador.
        </ModText>
        <ModText>
          Por descontado, se excluyen de estas medidas las personas que
          presenten síntomas o en cuarentena.
        </ModText>
        <ModImage
          src="/images/svg/01_may_huertoautocunsumo.svg"
          alt="regulacion huertos autoconsumo covid-19"
        />
        <ModText>
          El Boletín Oficial del Estado también ha recogido la Orden que permite
          el{" "}
          <strong>
            desplazamiento para el cuidado de animales y la recolección de los
            huertos para el autoconsumo
          </strong>
          . Serán desplazamientos mínimos y por el tiempo indispensable.
        </ModText>
        <ModText>
          Asimismo, también ha quedado publicada la{" "}
          <strong>Orden Ministerial</strong> por la que se definen los criterios
          y requisitos de los arrendatarios que pueden{" "}
          <strong>acceder a los préstamos para el pago del alquiler</strong>.
        </ModText>
        <ModText>
          Investigadores del <strong>Centro Nacional de Biotecnología</strong>{" "}
          (CNB), del Consejo Superior de Investigaciones Científicas (CSIC),
          ensayarán con animales el{" "}
          <strong>candidato a vacuna frente a COVID</strong> la próxima semana.
          El proceso de generación de una vacuna pasará por varias fases, y
          necesitan varios meses cada una.
        </ModText>
        <ModImage
          src="/images/svg/01_may_investigacionvacuna.svg"
          alt="investigación vacuna"
        />
        <ModReferenceList title="GUÍAS Y DOCUMENTOS PUBLICADOS">
          <ReferenceRow
            link="https://www.boe.es/boe/dias/2020/05/01/pdfs/BOE-A-2020-4768.pdf"
            name="Orden para las actividades no profesionales de cuidado y
            recolección de producciones agrícolas"
          />
          <ReferenceRow
            link="http://www.ciencia.gob.es/portal/site/MICINN/menuitem.edc7f2029a2be27d7010721001432ea0/?vgnextoid=dfaad3768ffc1710VgnVCM1000001d04140aRCRD&vgnextchannel=4346846085f90210VgnVCM1000001034e20aRCRD&vgnextrefresh=1"
            name="Ensayo con animales del candidato a vacuna frente al
            COVID-19"
          />
          <ReferenceRow
            link="https://www.mitma.gob.es/vivienda/linea-avales-arrendamiento-covid-19"
            name="Línea de avales arrendamiento COVID-19"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day49;
